<!-- 收货单位 平台 库存详情 -->
<template>
  <div class="van-main stock-detail">
    <div class="area-title">
      <p class="title">
        基本信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell title="申请支付编号" :value="utils.isEffectiveCommon(stockedDetail.paymentNo)" :required="true" />
      <van-cell title="所属项目名称" :value="utils.isEffectiveCommon(stockedDetail.inPlan.projectName)" />
      <van-cell title="关联订单" :value="utils.isEffectiveCommon(stockedDetail.inPlan.orderName)" />
      <van-cell title="委托客户" :value="utils.isEffectiveCommon(stockedDetail.inPlan.dsEnterpriseName)" />
      <van-cell title="提单号" :value="utils.isEffectiveCommon(stockedDetail.inPlan.goodsList ? stockedDetail.inPlan.goodsList[0].cabinetNo : '')" :required="true" />
      <van-cell title="所属合同号（PI/SC）" :value="utils.isEffectiveCommon(stockedDetail.inPlan.contractNo)" />
      <van-cell title="货物名称" :value="utils.isEffectiveCommon(stockedDetail.goodsName)" />
      <van-cell title="收货仓库" :value="utils.isEffectiveCommon(stockedDetail.inPlan.warehouseName)" />
      <van-cell title="账面重量(KG)" :value="utils.moneyFormat(stockedDetail.bookWeight)" :required="true" />
      <van-cell title="库存重量(KG)" :value="utils.moneyFormat(stockedDetail.stockWeight)" />
      <van-cell title="可提重量(KG)" :value="utils.moneyFormat(Number(stockedDetail.bookWeight)-Number(stockedDetail.lockWeight))" />
    </div>
    <div class="area-title">
      <p class="title">
        质数量单据
      </p>
    </div>
    <div class="van-common-detail">
      <file-list :file-list="fileDetailInfoVos" title="" />
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, Popup, List, NavBar, Calendar, Field, Picker } from 'vant'
export default {
  components: {
    fileList,
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Calendar.name]: Calendar,
    [Field.name]: Field,
    [Picker.name]: Picker
  },
  data () {
    return {
      batchId: this.$route.query.batchId,
      stockedDetail: {
        inPlan: {}
      },
      fileDetailInfoVos: []
    }
  },
  computed: {
  },
  created () {
    if (this.batchId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.api.storageManagementByWeight.out.stockedDetail({ batchId: this.batchId }).then(result => {
        this.stockedDetail = result.data.value
        this.getFileList()
      }).finally(() => {
      })
    },
    getFileList () {
      this.api.base.file.pageList({ module: 'INPLAN_DOC', businessId: this.stockedDetail.inPlanId, pageSize: 100 }).then(result => {
        this.fileDetailInfoVos = result.data.value.list
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.stock-detail {
  margin-bottom: 70px;
}
</style>
