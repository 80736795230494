var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main stock-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c("van-cell", {
            attrs: {
              title: "申请支付编号",
              value: _vm.utils.isEffectiveCommon(_vm.stockedDetail.paymentNo),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "所属项目名称",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.projectName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "关联订单",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.orderName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "委托客户",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.dsEnterpriseName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提单号",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.goodsList
                  ? _vm.stockedDetail.inPlan.goodsList[0].cabinetNo
                  : ""
              ),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "所属合同号（PI/SC）",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.contractNo
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称",
              value: _vm.utils.isEffectiveCommon(_vm.stockedDetail.goodsName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "收货仓库",
              value: _vm.utils.isEffectiveCommon(
                _vm.stockedDetail.inPlan.warehouseName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "账面重量(KG)",
              value: _vm.utils.moneyFormat(_vm.stockedDetail.bookWeight),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "库存重量(KG)",
              value: _vm.utils.moneyFormat(_vm.stockedDetail.stockWeight)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "可提重量(KG)",
              value: _vm.utils.moneyFormat(
                Number(_vm.stockedDetail.bookWeight) -
                  Number(_vm.stockedDetail.lockWeight)
              )
            }
          })
        ],
        1
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c("file-list", {
            attrs: { "file-list": _vm.fileDetailInfoVos, title: "" }
          })
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 基本信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 质数量单据 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }